import React, { Component } from 'react';
// import Nav from '../Nav'
import './Hero.css'

class Hero extends Component {
    render() {
        return (
            <div id="hero_div">
                {/* <Nav navItems={this.props.navItems}/> */}
                <img id="nav_logo" src="/assets/img/logo.png" alt="Logo" />
                <div id="hero_text_div">
                    <h1>A Tampa Bay Web Development Company.</h1>
                    {/* <p>This is some text inside a div block.</p> */}
                </div>
                {/* <div id="buttons_div">
                    <a href="/" className="button button_main">See Our Work</a>
                    <a href="/" className="button">Contact Us</a>
                </div> */}
            </div>
        )
    }
}

export default Hero;